
.fadeInUpCard {
    -webkit-animation: fadeInUpCard 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpCard 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpCard 1s; /* Internet Explorer */
    -o-animation: fadeInUpCard 1s; /* Opera < 12.1 */
    animation: fadeInUpCard 1s;
}

@keyframes fadeInUpCard {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


/* Firefox < 16 */
@-moz-keyframes fadeInUpCard {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeInUpCard {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Internet Explorer */
@-ms-keyframes fadeInUpCard {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Opera < 12.1 */
@-o-keyframes fadeInUpCard {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeOutDown {
    -webkit-animation: fadeOutDown 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeOutDown 1s; /* Firefox < 16 */
    -ms-animation: fadeOutDown 1s; /* Internet Explorer */
    -o-animation: fadeOutDown 1s; /* Opera < 12.1 */
    animation: fadeOutDown 1s;
    animation-delay: 5s;
}
.fadeOutDown_3 {
    -webkit-animation: fadeOutDown 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeOutDown 3s; /* Firefox < 16 */
    -ms-animation: fadeOutDown 3s; /* Internet Explorer */
    -o-animation: fadeOutDown 3s; /* Opera < 12.1 */
    animation: fadeOutDown 3s;
}

@keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(200px);
    }
}


/* Firefox < 16 */
@-moz-keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(200px);
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(200px);
    }
}

/* Internet Explorer */
@-ms-keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(200px);
    }
}

/* Opera < 12.1 */
@-o-keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(200px);
    }
}

