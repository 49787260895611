.hero {
    transition: all 1s ease-in-out;
    background-size: cover;
    background-position: center;
    z-index: -1;
    minHeight: 100vh;
    maxHeight: 100vh;
    overflow: hidden;
}


@keyframes fade {
    0% { opacity: 0.8 }
    5% { opacity: 1 }
    95% { opacity: 1 }
    100% { opacity: 0.8 }
}

@keyframes fade-out {
    0% { opacity: 1 }
    100% { opacity: 0.6 }
}




.fadeOutDown {
    -webkit-animation: fadeOutDown 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeOutDown 2s; /* Firefox < 16 */
    -ms-animation: fadeOutDown 2s; /* Internet Explorer */
    -o-animation: fadeOutDown 2s; /* Opera < 12.1 */
    animation: fadeOutDown 2s;
    animation-delay: 14.3s;
}
.fadeOutDown_3 {
    -webkit-animation: fadeOutDown 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeOutDown 3s; /* Firefox < 16 */
    -ms-animation: fadeOutDown 3s; /* Internet Explorer */
    -o-animation: fadeOutDown 3s; /* Opera < 12.1 */
    animation: fadeOutDown 3s;
    animation-delay: 12.5s;
}

@keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(200px);
    }
}


/* Firefox < 16 */
@-moz-keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(200px);
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(200px);
    }
}

/* Internet Explorer */
@-ms-keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(200px);
    }
}

/* Opera < 12.1 */
@-o-keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(200px);
    }
}
