.fadeInUpCardAbout {
    -webkit-animation: fadeInUpCard 0.8s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpCard 0.8s; /* Firefox < 16 */
    -ms-animation: fadeInUpCard 0.8s; /* Internet Explorer */
    -o-animation: fadeInUpCard 0.8s; /* Opera < 12.1 */
    animation: fadeInUpCard 0.8s;
}

@keyframes fadeInUpCard {
    0% {
        opacity: 0;
        transform: translateY(500px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


/* Firefox < 16 */
@-moz-keyframes fadeInUpCard {
    0% {
        opacity: 0;
        transform: translateY(500px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeInUpCard {
    0% {
        opacity: 0;
        transform: translateY(500px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Internet Explorer */
@-ms-keyframes fadeInUpCard {
    0% {
        opacity: 0;
        transform: translateY(500px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Opera < 12.1 */
@-o-keyframes fadeInUpCard {
    0% {
        opacity: 0;
        transform: translateY(500px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-about {
    -webkit-animation: fade 0.8s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fade 0.8s; /* Firefox < 16 */
    -ms-animation: fade 0.8s; /* Internet Explorer */
    -o-animation: fade 0.8s; /* Opera < 12.1 */
    animation: fade 0.8s;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/* Firefox < 16 */
@-moz-keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
